import { render, staticRenderFns } from "./GridSection.vue?vue&type=template&id=fc52ad76&scoped=true"
import script from "./GridSection.vue?vue&type=script&lang=js"
export * from "./GridSection.vue?vue&type=script&lang=js"
import style0 from "./GridSection.vue?vue&type=style&index=0&id=fc52ad76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc52ad76",
  null
  
)

export default component.exports