import Vue from "vue";
import VueRouter from "vue-router";

import authGuard from "../utils/auth/authGuard";
import accountFinishedCheck from "../utils/auth/accountFinishedCheck";
import provisionalLoginCheck from "../utils/auth/provisionalLoginCheck";
import seriesLookup from "../utils/auth/seriesLookup";
import eventLookup from "../utils/auth/eventLookup";
import eventOrganizerGuard from "../utils/auth/eventOrganizerGuard";
import seriesOrganizerGuard from "../utils/auth/seriesOrganizerGuard";
import chapterOrganizerGuard from "../utils/auth/chapterOrganizerGuard";
import adminGuard from "../utils/auth/adminGuard";
import homeGuard from "../utils/auth/homeGuard";
import seriesOnlyGuard from "../utils/auth/seriesOnlyGuard";
import manifestEventGuard from "../utils/auth/manifestEventGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: homeGuard,
    component: () => import("../features/home/Home.vue")
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("../features/home/Maintenance.vue")
  },
  //user
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("../features/users/auth/views/SignIn.vue"),
    props: route => ({
      reason: route.params.reason,
      tab: route.query.tab
    })
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../features/users/auth/views/ResetPassword.vue")
  },
  {
    path: "/reset-password/:token",
    name: "ResetPasswordToken",
    component: () => import("../features/users/auth/views/ResetPassword.vue")
  },
  {
    path: "/complete-account",
    name: "CompleteAccount",
    component: () => import("../features/users/auth/views/CompleteAccount.vue")
  },
  {
    path: "/complete-account/:token",
    name: "CompleteAccountToken",
    component: () => import("../features/users/auth/views/CompleteAccount.vue")
  },
  {
    path: "/users/:userId",
    name: "UserProfile",
    component: () => import("../features/users/dashboard/views/DashboardProfileView.vue"),
    props: { isDashboard: false }
  },
  //admin
  {
    path: "/a",
    name: "AdminIndex",
    beforeEnter: adminGuard,
    component: () => import("../features/home/views/AdminIndex.vue")
  },
  //user admin
  {
    path: "/a/user-search",
    name: "UserSearch",
    beforeEnter: adminGuard,
    component: () => import("../features/users/admin/views/UserSearch.vue")
  },
  {
    path: "/a/user/:userId",
    name: "UserView",
    beforeEnter: adminGuard,
    component: () => import("../features/users/admin/views/UserView.vue")
  },
  {
    path: "/a/unresolved-complaints",
    name: "UnresolvedComplaints",
    beforeEnter: adminGuard,
    component: () => import("../features/users/admin/views/UnresolvedComplaints.vue")
  },
  //dashboard
  {
    path: "/dashboard/",
    name: "DashboardEvents",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardEvents.vue")
  },
  {
    path: "/dashboard/profile",
    name: "DashboardProfileView",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardProfileView.vue"),
    props: { isDashboard: true }
  },
  {
    path: "/dashboard/profile/edit",
    name: "DashboardProfileEdit",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardProfileEdit.vue")
  },
  {
    path: "/dashboard/mail",
    name: "DashboardMail",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardMail.vue")
  },
  {
    path: "/dashboard/settings",
    name: "DashboardSettingsView",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsView.vue")
  },
  {
    path: "/dashboard/settings/view/:token",
    name: "DashboardSettingsViewToken",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsView.vue")
  },
  {
    path: "/dashboard/settings/edit",
    name: "DashboardSettingsEdit",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsEdit.vue")
  },
  {
    path: "/series/:seriesId/event-edit/:eventId",
    name: "EventEdit",
    beforeEnter: eventOrganizerGuard,
    component: () => import("../features/events/edit/views/EventEdit.vue")
  },
  {
    path: "/online-events",
    name: "OnlineEventDiscovery",
    component: () => import("../features/events/discovery/views/OnlineEventDiscovery.vue"),
    props: route => ({
      selectedDate: route.query.selectedDate || null
    })
  },
  //legacy event routes that are still needed
  {
    path: "/events/:slug-writing-group/:event_id",
    name: "event_detail",
    beforeEnter: eventLookup
  },
  {
    path: "/events/:slug/:event_id",
    name: "event_detail_non_seo",
    beforeEnter: eventLookup
  },
  //series
  {
    path: "/chapter/:chapterSlug/series/:seriesSlug/event/:eventId",
    name: "Series",
    component: () => import("../features/series/details/views/Series.vue"),
    beforeEnter: manifestEventGuard
  },
  {
    path: "/chapter/:chapterSlug/series/:seriesId",
    name: "SeriesOnly",
    beforeEnter: seriesOnlyGuard
  },
  {
    path: "/chapter/:chapterId/series-create/:seriesId?",
    name: "SeriesCreate",
    beforeEnter: chapterOrganizerGuard,
    component: () => import("../features/series/create/views/SeriesCreate.vue")
  },
  {
    path: "/chapter/:chapterId/series-edit/:seriesId",
    name: "SeriesEdit",
    beforeEnter: seriesOrganizerGuard,
    component: () => import("../features/series/create/views/SeriesEdit.vue")
  },
  //legacy series routes that are still needed
  {
    path: "/series-lookup/:event_template_id",
    name: "legacy_series_lookup",
    beforeEnter: seriesLookup
  },
  {
    path: "/series/:slug/:event_template_id",
    name: "legacy_series",
    beforeEnter: seriesLookup
  },
  //series admin
  {
    path: "/a/series/:seriesId",
    name: "SeriesView",
    component: () => import("../features/series/admin/views/SeriesView.vue")
  },
  //chapter
  {
    path: "/chapter/:chapterId/:chapterSlug",
    name: "Chapter",
    component: () => import("../features/chapters/details/views/Chapter.vue")
  },
  {
    path: "/a/chapter-create",
    name: "ChapterCreate",
    beforeEnter: adminGuard,
    component: () => import("../features/chapters/create/views/ChapterCreate.vue")
  },
  {
    path: "/chapters",
    name: "ChapterDiscovery",
    component: () => import("../features/chapters/discovery/views/ChapterDiscovery.vue")
  },
  //chapter admin
  {
    path: "/a/chapter-search",
    name: "ChapterSearch",
    beforeEnter: adminGuard,
    component: () => import("../features/chapters/admin/views/ChapterSearch.vue")
  },
  {
    path: "/a/chapter/:chapterId",
    name: "ChapterView",
    beforeEnter: adminGuard,
    component: () => import("../features/chapters/admin/views/ChapterView.vue")
  },
  //Prototyping/testing
  // {
  //   path: "/apiTest",
  //   name: "ApiTest",
  //   beforeEnter: adminGuard,
  //   component: () => import("../features/prototype/ApiTest.vue")
  // },
  {
    path: "/tiptaptest",
    name: "TipTapTest",
    beforeEnter: adminGuard,
    component: () => import("../features/prototype/TipTapTest.vue")
  },
  //resources
  {
    path: "/resources",
    name: "ResourceDiscovery",
    component: () => import("../features/resources/views/ResourceDiscovery.vue")
  },
  {
    path: "/about/suaw",
    name: "About",
    component: () => import("../features/resources/views/About.vue"),
    alias: ["/resources/about", "/about", "/about/about-suaw"]
  },
  {
    path: "/about/method",
    name: "Method",
    component: () => import("../features/resources/views/Method.vue"),
    alias: ["/resources/method"]
  },
  {
    path: "/about/impact",
    name: "Impact",
    component: () => import("../features/resources/views/Impact.vue"),
    alias: ["/resources/impact"]
  },
  {
    path: "/about/get-involved",
    name: "GetInvolved",
    component: () => import("../features/resources/views/GetInvolved.vue"),
    alias: ["/resources/get-involved"]
  },
  {
    path: "/resources/become-an-organizer",
    name: "BecomeAnOrganizer",
    component: () => import("../features/resources/views/BecomeAnOrganizer.vue"),
    alias: ["/host", "/organizer", "/resources/organizer"]
  },
  {
    path: "/resources/downloads",
    name: "Downloads",
    component: () => import("../features/resources/views/Downloads.vue"),
    alias: ["/downloads"]
  },
  {
    path: "/resources/code-of-conduct",
    name: "CodeOfConduct",
    component: () => import("../features/resources/views/CodeOfConduct.vue"),
    alias: ["/about/code-of-conduct", "/code-of-conduct"]
  },
  {
    path: "/about/faq",
    name: "Faq",
    component: () => import("../features/resources/views/Faq.vue"),
    alias: ["/resources/faq", "/faq"]
  },
  {
    path: "/resources/contact",
    name: "Contact",
    component: () => import("../features/resources/views/Contact.vue"),
    alias: ["/contact"]
  },
  {
    path: "/about/organization",
    name: "OurOrganization",
    component: () => import("../features/resources/views/OurOrganization.vue"),
    alias: ["/resources/our-organization", "/about/our-organization"]
  },
  {
    path: "/resources/organizer/organizer-faq",
    name: "OrganizerFaq",
    component: () => import("../features/resources/views/OrganizerFaq.vue"),
    alias: ["/organizer-faq", "/resources/organizer-faq"]
  },
  {
    path: "/resources/organizer/organizer-handbook",
    name: "OrganizerHandbook",
    component: () => import("../features/resources/views/OrganizerHandbook.vue"),
    alias: ["/organizer-handbook", "/resources/organizer-handbook"]
  },
  {
    path: "/resources/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../features/resources/views/PrivacyPolicy.vue"),
    alias: ["/privacy-policy"]
  },
  {
    path: "/resources/terms-of-service",
    name: "TermsOfService",
    component: () => import("../features/resources/views/TermsOfService.vue"),
    alias: ["/tos", "/resources/tos"]
  },
  {
    path: "/resources/legal",
    name: "Legal",
    component: () => import("../features/resources/views/Legal.vue"),
    alias: ["/legal"]
  },
  // articles (and legacy article routes)
  {
    path: "/resources/organizer/new-organizer-start-here",
    name: "NewOrganizerStartHere",
    component: () => import("../features/resources/views/organizer/NewOrganizerStartHere.vue")
  },
  {
    path: "/resources/organizer/before-your-video-call",
    name: "BeforeYourVideoCall",
    component: () => import("../features/resources/views/organizer/BeforeYourVideoCall.vue")
  },
  {
    path: "/resources/organizer/promoting-your-event",
    name: "PromotingYourEvent",
    component: () => import("../features/resources/views/organizer/PromotingYourEvent.vue")
  },
  {
    path: "/resources/organizer/academic-organizers",
    name: "AcademicOrganizers",
    component: () => import("../features/resources/views/organizer/AcademicOrganizers.vue")
  },
  {
    path: "/resources/organizer/listing-your-in-person-event",
    name: "ListingYourInPersonEvent",
    component: () => import("../features/resources/views/organizer/ListingYourInPersonEvent.vue")
  },
  {
    path: "/resources/organizer/hosting-your-in-person-event",
    name: "HostingYourInPersonEvent",
    component: () => import("../features/resources/views/organizer/HostingYourInPersonEvent.vue")
  },
  {
    path: "/resources/organizer/in-person-event-checklist",
    name: "InPersonEventChecklist",
    component: () => import("../features/resources/views/organizer/InPersonEventChecklist.vue")
  },
  {
    path: "/resources/organizer/listing-your-online-event",
    name: "ListingYourOnlineEvent",
    component: () => import("../features/resources/views/organizer/ListingYourOnlineEvent.vue")
  },
  {
    path: "/resources/organizer/hosting-your-online-event",
    name: "HostingYourOnlineEvent",
    component: () => import("../features/resources/views/organizer/HostingYourOnlineEvent.vue")
  },
  {
    path: "/resources/organizer/online-event-checklist",
    name: "OnlineEventChecklist",
    component: () => import("../features/resources/views/organizer/OnlineEventChecklist.vue")
  },
  {
    path: "/resources/organizer/troubleshooting-at-events",
    name: "TroubleshootingAtEvents",
    component: () => import("../features/resources/views/organizer/TroubleshootingAtEvents.vue")
  },
  //articles
  {
    path: "/resources/articles",
    name: "ArticleDiscovery",
    component: () => import("../features/articles/views/ArticleDiscovery.vue"),
    alias: ["/articles"]
  },
  {
    path: "/resources/articles/beginning-tips-for-writers-the-basics",
    name: "articles-beginning-tips-for-writers-the-basics",
    component: () => import("../features/articles/views/20160213_BeginningTipsForWritersTheBasics.vue"),
    alias: ["/beginning-tips-for-writers-the-basics", "/articles/beginning-tips-for-writers-the-basics"]
  },
  {
    path: "/resources/articles/conquer-procrastination-and-get-writing",
    name: "articles-conquer-procrastination-and-get-writing",
    component: () => import("../features/articles/views/20160403_ConquerProcrastinationAndGetWriting.vue"),
    alias: ["/conquer-procrastination-and-get-writing", "/articles/conquer-procrastination-and-get-writing"]
  },
  {
    path: "/resources/articles/perfectionism-distracts-you-from-writing-perfectly",
    name: "articles-perfectionism-distracts-you-from-writing-perfectly",
    component: () => import("../features/articles/views/20160405_PerfectionismDistractsYouFromWritingPerfectly.vue"),
    alias: ["/perfectionism-distracts-you-from-writing-perfectly", "/articles/perfectionism-distracts-you-from-writing-perfectly"]
  },
  {
    path: "/resources/articles/beginning-tips-for-writers-working-styles-and-wip",
    name: "articles-beginning-tips-for-writers-working-styles-and-wip",
    component: () => import("../features/articles/views/20160416_BeginningTipsForWritersWorkingStylesAndWip.vue"),
    alias: ["/beginning-tips-for-writers-working-styles-and-wip", "/articles/beginning-tips-for-writers-working-styles-and-wip"]
  },
  {
    path: "/resources/articles/after-the-first-draft-pro-tips-for-editing-your-work",
    name: "articles-after-the-first-draft-pro-tips-for-editing-your-work",
    component: () => import("../features/articles/views/20160518_AfterTheFirstDraftProTipsForEditingYourWork.vue"),
    alias: ["/after-the-first-draft-pro-tips-for-editing-your-work", "/articles/after-the-first-draft-pro-tips-for-editing-your-work"]
  },
  {
    path: "/resources/articles/five-geeky-hobbies-for-writers-that-arent-reading",
    name: "articles-five-geeky-hobbies-for-writers-that-arent-reading",
    component: () => import("../features/articles/views/20170110_FiveGeekyHobbiesForWritersThatArentReading.vue"),
    alias: ["/five-geeky-hobbies-for-writers-that-arent-reading", "/articles/five-geeky-hobbies-for-writers-that-arent-reading"]
  },
  {
    path: "/resources/articles/attending-the-muse-or-your-writing-discipline",
    name: "articles-attending-the-muse-or-your-writing-discipline",
    component: () => import("../features/articles/views/20170228_AttendingTheMuseOrYourWritingDiscipline.vue"),
    alias: ["/attending-the-muse-or-your-writing-discipline", "/articles/attending-the-muse-or-your-writing-discipline"]
  },
  {
    path: "/resources/articles/its-okay-to-be-edited-really",
    name: "articles-its-okay-to-be-edited-really",
    component: () => import("../features/articles/views/20170901_ItsOkayToBeEditedReally.vue"),
    alias: ["/its-okay-to-be-edited-really", "/articles/its-okay-to-be-edited-really"]
  },
  {
    path: "/resources/articles/5-simple-habits-to-boost-your-writing",
    name: "articles-5-simple-habits-to-boost-your-writing",
    component: () => import("../features/articles/views/20180204_FiveSimpleHabitsToBoostYourWriting.vue"),
    alias: ["/5-simple-habits-to-boost-your-writing", "/articles/5-simple-habits-to-boost-your-writing"]
  },
  {
    path: "/resources/articles/4-easy-steps-to-a-writing-plan",
    name: "articles-4-easy-steps-to-a-writing-plan",
    component: () => import("../features/articles/views/20180412_FourEasyStepsToAWritingPlan.vue"),
    alias: ["/4-easy-steps-to-a-writing-plan", "/articles/4-easy-steps-to-a-writing-plan"]
  },
  {
    path: "/resources/articles/shut-up-and-writes-nanowrimo-survival-guide",
    name: "articles-shut-up-and-writes-nanowrimo-survival-guide",
    component: () => import("../features/articles/views/20191101_ShutUpAndWritesNanowrimoSurvivalGuide.vue"),
    alias: ["/shut-up-and-writes-nanowrimo-survival-guide", "/articles/shut-up-and-writes-nanowrimo-survival-guide"]
  },
  {
    path: "/resources/articles/five-ways-to-silence-your-inner-critic-while-drafting",
    name: "articles-five-ways-to-silence-your-inner-critic-while-drafting",
    component: () => import("../features/articles/views/20191201_FiveWaysToSilenceYourInnerCriticWhileDrafting.vue"),
    alias: ["/five-ways-to-silence-your-inner-critic-while-drafting", "/articles/five-ways-to-silence-your-inner-critic-while-drafting"]
  },
  {
    path: "/resources/articles/creating-your-writing-environment",
    name: "articles-creating-your-writing-environment",
    component: () => import("../features/articles/views/20200129_CreatingYourWritingEnvironment.vue"),
    alias: ["/creating-your-writing-environment", "/articles/creating-your-writing-environment"]
  },
  {
    path: "/resources/articles/the-writers-guide-to-editors",
    name: "articles-the-writers-guide-to-editors",
    component: () => import("../features/articles/views/20200221_TheWritersGuideToEditors.vue"),
    alias: ["/the-writers-guide-to-editors", "/articles/the-writers-guide-to-editors"]
  },
  {
    path: "/resources/articles/10-best-practices-for-writing-from-home",
    name: "articles-10-best-practices-for-writing-from-home",
    component: () => import("../features/articles/views/20200320_TenBestPracticesForWritingFromHome.vue"),
    alias: ["/10-best-practices-for-writing-from-home", "/articles/10-best-practices-for-writing-from-home"]
  },
  {
    path: "/resources/articles/the-writers-guide-to-copyright",
    name: "articles-the-writers-guide-to-copyright",
    component: () => import("../features/articles/views/20200406_TheWritersGuideToCopyright.vue"),
    alias: ["/the-writers-guide-to-copyright", "/articles/the-writers-guide-to-copyright"]
  },
  {
    path: "/resources/articles/seven-strategies-to-get-you-writing-again",
    name: "articles-seven-strategies-to-get-you-writing-again",
    component: () => import("../features/articles/views/20200526_SevenStrategiesToGetYouWritingAgain.vue"),
    alias: ["/seven-strategies-to-get-you-writing-again", "/articles/seven-strategies-to-get-you-writing-again"]
  },
  {
    path: "/resources/articles/four-essential-steps-to-developing-your-writing-practice",
    name: "articles-four-essential-steps-to-developing-your-writing-practice",
    component: () => import("../features/articles/views/20200527_FourEssentialStepsToDevelopingYourWritingPractice.vue"),
    alias: ["/four-essential-steps-to-developing-your-writing-practice", "/articles/four-essential-steps-to-developing-your-writing-practice"]
  },
  {
    path: "/resources/articles/how-to-get-feedback-on-your-writing",
    name: "articles-how-to-get-feedback-on-your-writing",
    component: () => import("../features/articles/views/20200706_HowToGetFeedbackOnYourWriting.vue"),
    alias: ["/how-to-get-feedback-on-your-writing", "/articles/how-to-get-feedback-on-your-writing"]
  },
  {
    path: "/resources/articles/tricks-for-writing-under-pressure",
    name: "articles-tricks-for-writing-under-pressure",
    component: () => import("../features/articles/views/20200715_TricksForWritingUnderPressure.vue"),
    alias: ["/tricks-for-writing-under-pressure", "/articles/tricks-for-writing-under-pressure"]
  },
  {
    path: "/resources/articles/how-to-get-a-book-cover-youre-proud-of",
    name: "articles-how-to-get-a-book-cover-youre-proud-of",
    component: () => import("../features/articles/views/20200814_HowToGetABookCoverYoureProudOf.vue"),
    alias: ["/how-to-get-a-book-cover-youre-proud-of", "/articles/how-to-get-a-book-cover-youre-proud-of"]
  },
  {
    path: "/resources/articles/finding-and-working-with-a-beta-reader",
    name: "articles-finding-and-working-with-a-beta-reader",
    component: () => import("../features/articles/views/20200818_FindingAndWorkingWithABetaReader.vue"),
    alias: ["/finding-and-working-with-a-beta-reader", "/articles/finding-and-working-with-a-beta-reader"]
  },
  {
    path: "/resources/articles/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    name: "articles-how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    component: () => import("../features/articles/views/20200821_HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite.vue"),
    alias: ["/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write", "/articles/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write"]
  },
  {
    path: "/resources/articles/how-to-edit-the-first-draft-of-your-novel",
    name: "articles-how-to-edit-the-first-draft-of-your-novel",
    component: () => import("../features/articles/views/20200921_HowToEditTheFirstDraftOfYourNovel.vue"),
    alias: ["/how-to-edit-the-first-draft-of-your-novel", "/articles/how-to-edit-the-first-draft-of-your-novel"]
  },
  {
    path: "/resources/articles/between-editing-and-submission",
    name: "articles-between-editing-and-submission",
    component: () => import("../features/articles/views/20200929_BetweenEditingAndSubmission.vue"),
    alias: ["/between-editing-and-submission", "/articles/between-editing-and-submission"]
  },
  {
    path: "/resources/articles/how-to-find-a-literary-agent",
    name: "articles-how-to-find-a-literary-agent",
    component: () => import("../features/articles/views/20201026_HowToFindALiteraryAgent.vue"),
    alias: ["/how-to-find-a-literary-agent", "/articles/how-to-find-a-literary-agent"]
  },
  {
    path: "/resources/articles/make-writing-a-habit-not-a-choice",
    name: "articles-make-writing-a-habit-not-a-choice",
    component: () => import("../features/articles/views/20201118_MakeWritingAHabitNotAChoice.vue"),
    alias: ["/make-writing-a-habit-not-a-choice", "/articles/make-writing-a-habit-not-a-choice"]
  },
  {
    path: "/resources/articles/staying-creative-while-the-world-is-burning",
    name: "articles-staying-creative-while-the-world-is-burning",
    component: () => import("../features/articles/views/20201118_StayingCreativeWhileTheWorldIsBurning.vue"),
    alias: ["/staying-creative-while-the-world-is-burning", "/articles/staying-creative-while-the-world-is-burning"]
  },
  {
    path: "/resources/articles/how-to-self-edit-in-six-painless-steps",
    name: "articles-how-to-self-edit-in-six-painless-steps",
    component: () => import("../features/articles/views/20201120_HowToSelfEditInSixPainlessSteps.vue"),
    alias: ["/how-to-self-edit-in-six-painless-steps", "/articles/how-to-self-edit-in-six-painless-steps"]
  },
  {
    path: "/resources/articles/should-you-self-publish-or-traditionally-publish",
    name: "articles-should-you-self-publish-or-traditionally-publish",
    component: () => import("../features/articles/views/20201208_ShouldYouSelfPublishOrTraditionallyPublish.vue"),
    alias: ["/should-you-self-publish-or-traditionally-publish", "/articles/should-you-self-publish-or-traditionally-publish"]
  },
  {
    path: "/resources/articles/how-to-work-with-an-illustrator",
    name: "articles-how-to-work-with-an-illustrator",
    component: () => import("../features/articles/views/20201216_HowToWorkWithAnIllustrator.vue"),
    alias: ["/how-to-work-with-an-illustrator", "/articles/how-to-work-with-an-illustrator"]
  },
  {
    path: "/resources/articles/how-to-keep-your-writing-on-track",
    name: "articles-how-to-keep-your-writing-on-track",
    component: () => import("../features/articles/views/20210113_HowToKeepYourWritingOnTrack.vue"),
    alias: ["/how-to-keep-your-writing-on-track", "/articles/how-to-keep-your-writing-on-track"]
  },
  {
    path: "/resources/articles/the-elements-of-storytelling-for-novelists",
    name: "articles-the-elements-of-storytelling-for-novelists",
    component: () => import("../features/articles/views/20210119_TheElementsOfStorytellingForNovelists.vue"),
    alias: ["/the-elements-of-storytelling-for-novelists", "/articles/the-elements-of-storytelling-for-novelists"]
  },
  {
    path: "/resources/articles/five-steps-to-improve-pacing-in-your-story",
    name: "articles-five-steps-to-improve-pacing-in-your-story",
    component: () => import("../features/articles/views/20210211_FiveStepsToImprovePacingInYourStory.vue"),
    alias: ["/five-steps-to-improve-pacing-in-your-story", "/articles/five-steps-to-improve-pacing-in-your-story"]
  },
  {
    path: "/resources/articles/submissions-the-harsh-reality-and-how-to-improve-your-odds",
    name: "articles-submissions-the-harsh-reality-and-how-to-improve-your-odds",
    component: () => import("../features/articles/views/20210603_SubmissionsTheHarshRealityAndHowToImproveYourOdds.vue"),
    alias: ["/submissions-the-harsh-reality-and-how-to-improve-your-odds", "/articles/submissions-the-harsh-reality-and-how-to-improve-your-odds"]
  },
  {
    path: "/resources/articles/five-ways-to-create-new-settings-in-your-writing",
    name: "articles-five-ways-to-create-new-settings-in-your-writing",
    component: () => import("../features/articles/views/20210628_FiveWaysToCreateNewSettingsInYourWriting.vue"),
    alias: ["/five-ways-to-create-new-settings-in-your-writing", "/articles/five-ways-to-create-new-settings-in-your-writing"]
  },
  {
    path: "/resources/articles/five-traits-of-an-unforgettable-scene",
    name: "articles-five-traits-of-an-unforgettable-scene",
    component: () => import("../features/articles/views/20210717_FiveTraitsOfAnUnforgettableScene.vue"),
    alias: ["/five-traits-of-an-unforgettable-scene", "/articles/five-traits-of-an-unforgettable-scene"]
  },
  {
    path: "/resources/articles/when-should-i-call-myself-a-writer",
    name: "articles-when-should-i-call-myself-a-writer",
    component: () => import("../features/articles/views/20230217_WhenShouldICallMyselfAWriter.vue"),
    alias: ["/when-should-i-call-myself-a-writer", "/articles/when-should-i-call-myself-a-writer"]
  },
  {
    path: "/resources/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    name: "articles-what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    component: () => import("../features/articles/views/20230127_WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas.vue"),
    alias: ["/what-should-i-write-about-five-ways-to-find-new-writing-ideas", "/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas"]
  },
  {
    path: "/resources/articles/i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    name: "articles-i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    component: () => import("../features/articles/views/20230321_IWrote1000WordsFor30DaysAndHereIsWhatHappened.vue"),
    alias: ["/i-wrote-1000-words-for-30-days-and-here-is-what-happened", "/articles/i-wrote-1000-words-for-30-days-and-here-is-what-happened"]
  },
  {
    path: "/resources/articles/how-to-make-friends-with-chat-gpt",
    name: "articles-how-to-make-friends-with-chat-gpt",
    component: () => import("../features/articles/views/20230501_HowToMakeFriendsWithChatGpt.vue"),
    alias: ["/how-to-make-friends-with-chat-gpt", "/articles/how-to-make-friends-with-chat-gpt"]
  },
  {
    path: "*",
    name: "404",
    component: () => import("../features/common/views/NotFoundPage.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(accountFinishedCheck);
router.beforeEach(provisionalLoginCheck);
//router.beforeEach(maintenanceCheck); //comment this line out (and deploy) when we aren't in maintenance mode

export default router;
