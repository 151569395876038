<script>
import Logo from "../Logo/Logo.vue";
import Link from "../Link/Link.vue";
import Icon from "../Icon/Icon.vue";

export default {
  name: 'Footer',
  components: {
    Logo,
    Link,
    Icon,
  },
  props: {
    copyright: {
      type: String,
      default: "Shut Up & Write! All rights reserved.",
    },
    bottomLinks: {
      type: Array,
      default: () => [
        { text: "Terms of Service", url: "/resources/terms-of-service" },
        { text: "Legal", url: "/resources/legal" },
        { text: "Privacy Policy", url: "/resources/privacy-policy" },
      ],
    },
    blocks: {
      type: Array,
      default: () => [
        { headlineText: "Contact Us", headlineUrl: "/resources/contact", address: "2261 Market Street #4978 San Francisco, CA 94114", socials: [
          { icon: "IconFacebook", url: "https://www.facebook.com/shutupnwrite/" },
          { icon: "IconInstagram", url: "https://www.instagram.com/shutupwrite/" },
          { icon: "IconBluesky", url: "https://bsky.app/profile/suaw.bsky.social/" },
        ]},
        { headlineText: "Resources", anchors: [
          { text: "Become an Organizer", url: "/resources/become-an-organizer" },
          { text: "Downloads", url: "/resources/downloads" },
          { text: "Store", url: "https://store.shutupwrite.com/" },
          { text: "About Us", url: "/about/about-suaw" },
        ]},
        { headlineText: "Support", anchors: [
          { text: "FAQ", url: "/about/faq" },
          { text: "Organizer Handbook", url: "/resources/organizer/organizer-handbook" },
          { text: "Organizer FAQ", url: "/resources/organizer/organizer-faq" },
          { text: "Code of Conduct", url: "/resources/code-of-conduct" },
        ]},
      ],
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    updateYear() {
      this.currentYear = new Date().getFullYear();
    },
  },
  mounted() {
    // Update the year automatically when the component is mounted
    this.updateYear();
  },
}
</script>

<template>
  <footer class="suaw-footer">
    <div class="suaw-footer__holder">
      <div class="suaw-footer__block">
        <Link
          to="/"
          class="suaw-footer__symbol"
        >
          <Logo
            type="LogoInitials"
            className="suaw-header__logo"
          />
        </Link>
      </div>
      <div
        v-for="(block, index) in blocks"
        :key="index"
        class="suaw-footer__block"
      >
        <component
          :is="block.headlineUrl ? 'Link' : 'strong'"
          :to="block.headlineUrl"
          color="dark"
          weight="bold"
          class="suaw-footer__headline"
        >{{ block.headlineText }}</component>
        <p
          v-if="block.address"
          class="suaw-footer__address"
        >{{ block.address }}</p>
        <ul
          v-if="block.anchors && block.anchors.length"
          class="suaw-footer__list"
        >
          <li
            v-for="(anchor, index) in block.anchors"
            :key="index"
            class="suaw-footer__item"
          >
            <Link
              color="dark"
              weight="default"
              :to="anchor.url"
            >{{ anchor.text }}</Link>
          </li>
        </ul>
        <nav
          v-if="block.socials && block.socials.length"
          class="suaw-footer__nav"
        >
          <Link
            v-for="(social, index) in block.socials"
            :key="index"
            color="dark"
            weight="default"
            :to="social.url"
            class="suaw-footer__social"
          >
            <Icon
              :icon="social.icon"
              class="suaw-footer__icon"
            />
          </Link>
        </nav>
      </div>
    </div>
    <hr class="suaw-footer__divider">
    <div class="suaw-footer__holder suaw-footer__holder--narrow">
      <p class="suaw-footer__copyright">&copy; {{ currentYear }} {{ copyright }}</p>
      <nav class="suaw-footer__nav">
        <Link
          v-for="(link, index) in bottomLinks"
          :key="index"
          color="dark"
          :to="link.url"
        >{{ link.text }}</Link>
      </nav>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.suaw-footer {
  background-color: var(--sem-color-primary-medium);

  @media screen and (min-width: 681px) {
    --comp-footer-holder-direction: row;
    --comp-footer-block-width: auto;
    --comp-footer-block-max-width: 180px;
  }

  @media screen and (max-width: 680px) {
    --comp-footer-holder-direction: column;
    --comp-footer-block-width: 100%;
    --comp-footer-block-max-width: none;
  }

  &__symbol:hover {
    opacity: .85;
  }

  &__holder {
    align-items: center;
    box-sizing: content-box;
    display: flex;
    flex-direction: var(--comp-footer-holder-direction);
    gap: var(--sem-space-double);
    justify-content: space-between;
    margin: auto;
    max-width: 1264px;
    padding: var(--sem-space-octuple) var(--sem-space-quadruple);
    @media (max-width: 767px) {
      padding: var(--sem-space-octuple) var(--sem-space-double); // Override padding for mobile view
    }
  }

  &__holder--narrow {
    padding: var(--sem-space-quintuple) var(--sem-space-quadruple);
    @media (max-width: 767px) {
      padding: var(--sem-space-quintuple) var(--sem-space-double); // Override padding for mobile view
    }
  }

  &__divider {
    border-color: var(--sem-color-border-darkest);
    border-top: 0;
    height: 1px;
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
  }

  &__block {
    max-width: var(--comp-footer-block-max-width);
    width: var(--comp-footer-block-width);
  }

  &__address,
  &__headline {
    margin-bottom: var(--sem-space-double);
  }

  &__headline {
    font-weight: 500;
    display: block;
  }

  &__nav {
    display: flex;
    gap: var(--sem-space-triple);
  }

  &__social:hover {
    opacity: .75;
  }

  &__icon {
    fill: var(--sem-color-text-darkest);
    height: 24px;
    width: 24px;
  }
}
</style>
